import React from 'react';
import ImageLoader from './ImageLoader';
import ImageLazyLoader from './ImageLazyLoader';

const Image = React.memo(
  ({ src, srcSet, onLoad, width, height, lazy = false, cover = true, sanityImage = false, alt, ...rest }: ImageProps) => {
    if (lazy)
      return <ImageLazyLoader src={src} srcSet={srcSet} onLoad={onLoad} cover={cover} width={width} height={height} alt={alt} {...rest} />;
    return (
      <ImageLoader
        src={src}
        srcSet={srcSet}
        onLoad={onLoad}
        cover={cover}
        sanityImage={sanityImage}
        width={width}
        height={height}
        alt={alt}
        {...rest}
      />
    );
  },
);

Image.displayName = 'Image';

type ImageProps = {
  src: string;
  srcSet?: string;
  alt: string;
  onLoad?: () => void;
  width: string | number | string[] | number[];
  maxWidth?: string | number | string[] | number[];
  height: string | number | string[] | number[];
  lazy?: boolean;
  cover?: boolean;
  sanityImage?: boolean;
  borderRadius?: string;
};

export default Image;
